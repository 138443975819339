export const baseAddresses = {
  Trading_Implementation: '0x2FF50BB515FF3578998749905cB3d3b08dF4E4b0',
  Trading: '0xC6b8664a92512826C721EA418e106F46126E3875',
  PairsStorage_Proxy: '0x71802017e2FA39A0086f9Aaa8Ca8B21Aaf7EcF00',
  TradingCallbacks: '0xC6f1fB92E65a92B39dD250c7881c9757bf124bFc',
  KravFactory: '0xD5d35A53ff9624C2516625B1ED22eD73f6EB070A',
  TradingStorage: '0x7497bFFDC5B833A18CbE1d1c87EE926beD165720',
  KravFactory_Proxy: '0xD5d35A53ff9624C2516625B1ED22eD73f6EB070A',
  TradingCallbacks_Proxy: '0xC6f1fB92E65a92B39dD250c7881c9757bf124bFc',
  PriceAggregator: '0xE756C23bC1c0c5D7A290a275C5999A6DcE2068D1',
  KToken_Proxy: '0x5bc56EA62BFaE2c71fd7696d32baEe7df728fa79',
  DefaultProxyAdmin: '0x43178FF190b27A34a59a7f19BAbd6297d44046B6',
  TriggerInfo_Implementation: '0x0c97E1E55a7df130aA7967B6b3A5E79a31e3ECe4',
  PairInfos_Proxy: '0x0FA3FFd6D073E8772258b318895CB0eE81CE977e',
  PairsStorage_Implementation: '0xb78bE0A7B030f0F3D96522d104ddEA8D6335471f',
  PairInfos_Implementation: '0x681BDc35e2991BCa61310A5F93F8e318b5cA6127',
  KravFactory_Implementation: '0x727E98b7156C7C033D557FE7638837C53d889cff',
  PairsStorage: '0x71802017e2FA39A0086f9Aaa8Ca8B21Aaf7EcF00',
  KToken: '0x5bc56EA62BFaE2c71fd7696d32baEe7df728fa79',
  TradingStorage_Implementation: '0x3a6FAF0AA7621A1e1a599FD388f51e4Cd1464a82',
  KToken_Implementation: '0x7Cb55Cb920E46D962a65B9B72B6De51dFF1eFD7b',
  PairInfos: '0x0FA3FFd6D073E8772258b318895CB0eE81CE977e',
  Trading_Proxy: '0xC6b8664a92512826C721EA418e106F46126E3875',
  PriceAggregator_Proxy: '0xE756C23bC1c0c5D7A290a275C5999A6DcE2068D1',
  TriggerInfo_Proxy: '0xC5966eefEb4ab019BC48D9C3cc049f0EF1400510',
  TradingStorage_Proxy: '0x7497bFFDC5B833A18CbE1d1c87EE926beD165720',
  TradingCallbacks_Implementation: '0xF880950a6f95bc474c7fB20CbBf552aC173214a1',
  PriceAggregator_Implementation: '0x9aA597bcEE8888eFC15D2b27cd0731220Ac1A4Ae',
  TriggerInfo: '0xC5966eefEb4ab019BC48D9C3cc049f0EF1400510',
}
